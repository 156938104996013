import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Icon from "../Icon"
import Modal from "../Modal"
import OutboundLink from "../OutboundLink"
import Video from "../Video"
import "./styles.scss"

const Action = ({
  className,
  label,
  type,
  format,
  size,
  path,
  url,
  youtube_id,
  vimeo_id,
  guidSrc,
  poster,
  autoplay,
  locked,
  title,
  actions,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(null)

  const handleDropdownClick = () => setDropdownOpen(state => !state)

  return (
    <div
      className={classNames(
        "resource__action",
        locked && "resource__action--locked",
        type === "dropdown" && "resource__action--with-dropdown",
        className
      )}
    >
      {locked ? (
        <div className="resource__action-placeholder">
          <Icon name="lock" inline />
          {label}
        </div>
      ) : (
        <>
          {type === "external" && (
            <OutboundLink to={url} withIcon>
              {label}
            </OutboundLink>
          )}
          {type === "video" && (
            <Modal
              trigger={
                <button>
                  <Icon name="play" inline />
                  {label}
                </button>
              }
              video
            >
              <Video
                youtube_id={youtube_id}
                vimeo_id={vimeo_id}
                guidSrc={guidSrc}
                poster={poster}
                autoplay={autoplay}
                title={title}
              />
            </Modal>
          )}
          {type === "download" && (
            <a href={path} target="_blank" rel="noopener noreferrer">
              <Icon name="download" inline />
              {label}
            </a>
          )}
          {type === "module" && (
            <a href={path} target="_blank" rel="noopener noreferrer">
              <Icon name="module" inline />
              {label}
            </a>
          )}
          {type === "dropdown" && (
            <button
              onClick={handleDropdownClick}
              className="resource__dropdown-toggle"
            >
              <Icon
                name="caratdown"
                inline
                className={classNames(
                  "resource__dropdown-carat",
                  dropdownOpen && "resource__dropdown-carat--open"
                )}
              />
              {label}
            </button>
          )}
        </>
      )}

      {format && <span className="resource__format">{format}</span>}
      {size && <span className="resource__size">{size}</span>}
      {type === "dropdown" && dropdownOpen && (
        <div className="resource__dropdown-items">
          {actions.map((action, i) => (
            <Action {...action} key={i} />
          ))}
        </div>
      )}
    </div>
  )
}

Action.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  format: PropTypes.string,
  size: PropTypes.string,
  path: PropTypes.string,
  url: PropTypes.string,
  youtube_id: PropTypes.string,
  vimeo_id: PropTypes.string,
  locked: PropTypes.bool,
  title: PropTypes.string,
}

export default Action
